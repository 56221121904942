import React, { useState } from 'react';
import Layout from "../components/Dashboard/Layout/layout"
import Seo from "../components/seo"
import '../components/css/mantenimiento-productos.css';
import { Button, Modal, Col, Row, Container, Card } from'react-bootstrap';


// Import components
import ProductCard from '../components/Dashboard/ProductCard/index';
// Import icons
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlus, faIdCardAlt} from '@fortawesome/free-solid-svg-icons'
import Access from "../components/Acess"

import categoriesAPI from '../api/categories';
import productsAPI from '../api/products';




const Users = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [categoriesList, setCategoriesList] = useState([]);
    const [productsList, setProductsList] = useState([]);

    
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(categoriesList);
    const [price, setPrice] = useState(parseFloat(0.00).toFixed(2));
    const [sku, setSku] = useState('');
    const [presentation, setPresentation] = useState('');
    const [productDoc, setProductDoc] = useState('');
    const [file, setFile] = useState('');
    // 


    useState(async ()=>{
        if (typeof window !== 'undefined') {
            const categories = await categoriesAPI.readForUserID(localStorage.getItem('username'));
            const products = await productsAPI.readForUserID(localStorage.getItem('username'));
            setCategoriesList(categories?.categories?.length > 0 ? categories.categories : []);
            setProductsList(products?.products?.length > 0 ? products.products : []);
            setProductDoc(products?.id);
        }
    },[])

    const addProduct = async(newProduct) => {
        const productsData = [];
        productsList.forEach((product)=>productsData.push(product))
        const newList = await productsAPI.add(productDoc,localStorage.getItem('username'),newProduct, productsData);
        setFile('');
        setProductsList(newList.products);
    };

    return(
        <>
        <Layout>
            <Seo title="Mantenimiento de productos" />
            <Access section="product"/>

                {/* Modal addProduct */}
                <Modal show={show} onHide={handleClose} size='lg'>
                    <Modal.Header >
                    <Modal.Title>Agregar producto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <small className="form-text">Los elementos que tengan asterisco(*) son obligatorios.</small>
                                </div>
                            </div>
                        </div>
                        <form action="">
                            <div className="row m-2">
                                <div className="col-6">
                                    <label htmlFor="txtName" className="form-label">Nombre*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Ingresa nombre" 
                                        id="txtName"
                                        value={name}
                                        onChange={(e)=>{
                                            setName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="imgProductLabel" className="form-label">Imagen</label>
                                    <div className="text-center">
                                        {/* <progress className="progress text-center" value={file} max="100" style={{"width": "100%"}}></progress>
                                        <br /> */}
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            id="validatedCustomFile" 
                                            required 
                                            onChange={(e)=>{
                                                setFile(e.target.files[0]);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row m-2">
                                <div className="col-12">
                                    <label htmlFor="txtName" className="form-label">Descripción*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Ingresa una descripción" 
                                        id="txtDescription"
                                        value={description}
                                        onChange={(e)=>{
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row m-2">
                                <div className="col-6">
                                    <label htmlFor="txtSKU" className="form-label">SKU*</label>
                                    <input 
                                        type="text"
                                         className="form-control"
                                          placeholder="Ingresa elSKU" 
                                          id="txtSKU" 
                                          value={sku}
                                          onChange={(e)=>{
                                            setSku(e.target.value);
                                          }}
                                          />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="txtPrice" className="form-label">Precio*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Ingresa el precio" 
                                        id="txtPrice" 
                                        value={price}
                                        onChange={(e)=>{
                                            setPrice(e.target.value);
                                        }}
                                        />
                                </div>
                            </div>
                            <div className="row m-2">
                                <div className="col-6">
                                    <label htmlFor="txtCategory" className="form-label">Categoria</label>
                                    <select
                                        className='form-control'
                                        aria-label="Default select example" 
                                        id="SlCategory"
                                        onChange={(e)=>{
                                            setCategory(e.target.value);
                                        }}
                                    >
                                        <option>Select options</option>
                                        {
                                            categoriesList?.length != 0 ? 
                                                categoriesList.map((product)=>{
                                                return(
                                                    <option value={product.name}>{product.name}</option>
                                                )
                                            })
                                            : 
                                            <></>
                                        }
                                    </select>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="txtSet" className="form-label">Presentación</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Selecciona el conjunto" 
                                        id="txtSet" 
                                        value={presentation}
                                        onChange={(e)=>{
                                            setPresentation(e.target.value)
                                        }}
                                        />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" 
                        onClick={()=>{
                            handleClose();
                            addProduct({
                                name,
                                img:file,
                                description,
                                category,
                                price,
                                sku,
                                presentation
                            })
                        }}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
                {/* End Modal addProduct */}
                <Container fluid>
                    <Row style={
                    {
                        "backgroundColor": "#6389FF", 
                        "borderBottomLeftRadius": "30px", 
                        "borderBottomRightRadius": "30px"
                    }
                    }>
                    <Col md={3}>
                        <Card className=" mt-3 mb-3 shadow" style={{"borderRadius": "15px"}}>
                            <Card.Body>
                            <Card.Title style={{"color": "#5C5C5C"}}>
                                <FontAwesomeIcon icon={faIdCardAlt} style={
                                {
                                    "fontSize": "30px"
                                }
                                } /> Catalogo de productos
                            </Card.Title>
                            
                            </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>
                <div className="container-fluid">
                    <div className="row m-4">
                    {/* <div className="col-2 m-2">
                        <button className="btn btn-outline-dark"><FontAwesomeIcon icon={faArrowLeft} className="size-icon"/></button>
                    </div> */}
                    {/* title and subtitle*/}
                    <div className="col-12 m-2">
                        <h2>Administrar productos</h2>
                        <p className="subtitle">Puedes administrar los productos y precios que tendran así como detalles de importancia como imagenes.</p>
                    </div>
                    {/* Search and back button*/}
                    <div className="col-12 block-position">
                            <div className="row">
                                <div className="col-6">
                                    <input type="text" className="form-control search-control"  placeholder='Buscar'/>
                                </div>
                                <div className="col-2">
                                    <button 
                                        className="btn btn-outline-dark add-button" 
                                        onClick={handleShow}
                                        >
                                        <FontAwesomeIcon icon={faPlus} className="size-button-icon"/>
                                    </button>
                                </div>
                            </div>
                    </div>
                        {/* Cars or table */}
                            <ProductCard 
                                products={productsList}
                                categories={categoriesList}
                            />
                        {/* END REGION CARD PRODUCT */}
                    </div>
                </div>
        </Layout> 
        </>
    )
    
  
}

export default Users
